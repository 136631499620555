import React, {
  useMemo,
  useCallback,
  useEffect,
  memo,
  forwardRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Chip,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Page,
  DeleteDialog,
  SmartTable,
  Search,
  SuccessDialog,
} from "../../../components";
import {
  deleteMasterRecipient,
  listMasterRecipients,
  updateMasterRecipient,
} from "../../../actions/masterRecipient";
import Actions from "./Actions";
import { headersToColumns } from "../../RecipientLists/Edit/Edit";
import FormDialog from "../../../components/FormDialog";
import { getMasterListRecipientHeaders } from "../../../actions/newRecipientList";
import { useTableActions } from "../../../hooks/useTableActions";

function getParams(queryParams) {
  const { page, itemsPerPage, search } = queryParams;
  return {
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    search: search || "",
  };
}

const masterListColumns = "masterListColumns";
function getColumns(headers, t) {
  if (!headers) {
    return headers;
  }
  return [
    ...headersToColumns({ headers, searchable: true, t }),
    ...headersToColumns({ headers, searchable: false, t }),
  ];
}

const sortingArr = ["Name", "Contact", "Contact Type"];
function sortHeaders(a, b) {
  if (a.label === "Actions") {
    return 1; // Actions should come at the end, so after B: so positive number
  } if (b.label === "Actions") {
    return -1; // Actions should come at the end, so after A: so negative number
  } else if (sortingArr.includes(a.label) && sortingArr.includes(b.label)) {
    // Both are in sorting array so use position in array
    return sortingArr?.indexOf(a.label) - sortingArr.indexOf(b.label);
  } else if (sortingArr.includes(a.label)) {
    // A is in sorting array but B is not, so A should come before B, so negative number
    return -1;
  } else if (sortingArr.includes(b.label)) {
    // B is in sorting array but A is not, so A should come after B, so positive number
    return 1;
  } else {
    return a.label - b.label; // Else sort alphabetically
  }
}

function MasterRecipientListsBase() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const {
    data: masterRecipients,
    totalCount,
    recipientHeaders,
    recipientListId,
  } = useSelector((state) =>
    state.masterListRecipients ? state.masterListRecipients : { totalCount: 0 }
  );
  const isMasterListValid =
    recipientHeaders && Boolean(Object.keys(recipientHeaders).length);
  const [selectedRecipient, setSelectedRecipient] = useState({});
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [action, setAction] = useState("");
  const [recipientToDelete, setRecipientToDelete] = useState();
  const {
    onItemsPerPageChange,
    onPageChange,
    onSearchChange,
  } = useTableActions();
  const onEdit = useCallback((recipient) => {
    setSelectedRecipient(recipient);
    setOpen(!open);
  }, [setOpen, setSelectedRecipient]);
  const onOpenDeleteDialog = useCallback(
    (recipient) => setRecipientToDelete(recipient),
    [setRecipientToDelete]
  );
  const onCloseDeleteDialog = useCallback(() => setRecipientToDelete(null), [
    setRecipientToDelete,
  ]);

  const [encryptedColumns, setEncryptedColumns] = useState([]);
  const [searchableColumns, setSearchableColumns] = useState([]);

  const onConfirmEdit = async (result) => {
    setOpen(false);
    selectedRecipient.fields = result;
    await onEditRecipient(selectedRecipient);
    setAction("updated");
    setSuccessOpen(true);
  };
  const formFields = useMemo(() => {
    if (recipientHeaders && selectedRecipient._id) {
      delete recipientHeaders["ssn"];
    }
    return {
      ...recipientHeaders,
      contactType: !selectedRecipient._id
        ? {
            type: "array",
            items: {
              type: "string",
              enum: ["sms", "voice", "email", "tty"],
            },
          }
        : {},
    };
  }, [recipientHeaders, selectedRecipient]);

  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const activeCompanyId = useSelector((state) => state.storage.companyId);

  const params = useMemo(
    () => getParams(queryParams, activeDivisionId, activeCompanyId),
    [queryParams, activeDivisionId, activeCompanyId]
  );

  const onDelete = useCallback(async () => {
    await dispatch(deleteMasterRecipient(recipientToDelete));
    await dispatch(listMasterRecipients(params));
    setAction("deleted");
    setSuccessOpen(true);
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, recipientToDelete, params]);

  const formatLabels = (columns) => {
    if (columns) {
      return columns.map(({ label, ...restCol }) => {
        const splitLabel = label.replace(/([A-Z])/g, " $1");
        const titleCaseLabel =
          splitLabel.charAt(0).toUpperCase() + splitLabel.slice(1);
        return {
          ...restCol,
          label: t(titleCaseLabel),
        };
      });
    }
  };

  const computeColumns = () => {
    const columns = formatLabels(getColumns(recipientHeaders, t));
    if (columns && isMasterListValid) {
      columns.push({
        name: "actions",
        getter: (recipient) => (
          <Actions
            onEdit={() => onEdit(recipient)}
            onDelete={() => onOpenDeleteDialog(recipient)}
          />
        ),
        label: t("Actions"),
        sortable: false,
        align: "center",
      });
    }
    return columns;
  };

  const columns = useMemo(() => computeColumns(), [recipientHeaders, t]);

  const filterColumns = useCallback(() => {
    const encryptedHeaders = [];
    const searchableHeaders = [];
    if (recipientHeaders && Object.keys(recipientHeaders).length) {
      for (const { name, label } of columns) {
        if (name === "actions") {
        } else if (recipientHeaders[name]?.searchable === false) {
          encryptedHeaders.push(label);
        } else {
          searchableHeaders.push(label);
        }
      }
    }
    setEncryptedColumns(encryptedHeaders);
    setSearchableColumns(searchableHeaders);
  }, [columns, recipientHeaders, setEncryptedColumns, setSearchableColumns]);

  const columnsList = columns
    ?.map((col) => col.label !== "Actions" && col.label)
    ?.filter((col) => col);

  const defaultColumnsList = JSON.parse(localStorage.getItem(masterListColumns));
  const [selectedColumns, setSelectedColumns] = useState(defaultColumnsList);

  const displayColumns = useMemo(() => {
    return columns
      ?.filter((col) => {
        if ( col.label === "Actions") {
          // ECS-2768: Patch => Never filter out the Actions column from the Table
          return true;
        }
        // If column is selected and searchable
        if (selectedColumns?.includes(col.label) && recipientHeaders[String(col.name)]?.searchable) {
          return col;
        }
      })
      ?.sort(sortHeaders);
  }, [selectedColumns, recipientHeaders]);

  const onEditRecipient = useCallback(
    async (recipient) => {
      dispatch(
        updateMasterRecipient(
          recipient._id,
          {
            recipientListId: recipient.recipientListId,
            recipient: recipient.fields,
          },
          { userId: user.id }
        )
      ).then(() => {
        dispatch(listMasterRecipients(params));
      });
    },
    [dispatch, masterRecipients]
  );

  // When params change, refresh master recipient list headers
  useEffect(() => {
    dispatch(getMasterListRecipientHeaders());
  }, [dispatch, params]);

  // When params or recipientListId changes, refresh master recipients table
  useEffect(() => {
    if (recipientListId) {
      dispatch(listMasterRecipients(params));
    }
  }, [dispatch, params, recipientListId]);

  // When recipientHeaders are changed, update the filter dropdown
  useEffect(() => {
    filterColumns();
  }, [recipientHeaders]);

  useEffect(() => {
    if (!selectedColumns?.length && columnsList?.length) {
      setSelectedColumns(columnsList);
      localStorage.setItem(masterListColumns, JSON.stringify(columnsList));
    }
  }, [selectedColumns, columnsList]);

  const onColumnsChange = ({ target: { value, } }) => {
    const selectedCols = typeof value === "string" ? value.split(",") : value;

    if (selectedCols.length >= 1) {
      setSelectedColumns(selectedCols.filter((column) => searchableColumns.includes(column)));
      localStorage.setItem(masterListColumns, JSON.stringify(selectedCols));
    }
  };

  return (
    <Page p={2} pt={0}>
      {columnsList && (
        <Select
          multiple
          style={{ marginBottom: 20 }}
          value={selectedColumns || []}
          onChange={onColumnsChange}
          input={<OutlinedInput label="Columns" />}
          renderValue={(selected) => selected.join(", ")}
        >
          {/* Need to prepare this new variable split in the logic */}
          {searchableColumns.map((label) => (
            <MenuItem key={label} value={label}>
              <Checkbox checked={selectedColumns?.indexOf(label) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
          {/* This is the new stuff, note the DISABLED attributes. Would be okay with hiding the checkbox */}
          {encryptedColumns.length &&
            encryptedColumns.map((label) => (
              <MenuItem key={label} value={label} disabled={true}>
                <Checkbox checked={false} disabled={true} />
                <ListItemText primary={label} />
                <ListItemSecondaryAction>
                  <Chip label="Encrypted Field" />
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
        </Select>
      )}
      <Search
        variant="outlined"
        size="small"
        style={{
          marginBottom: 10,
          maxWidth: 500,
        }}
        label={t("Search")}
        onSearch={onSearchChange}
        defaultValue={params.search}
        debounce={300}
        fullWidth
      />
      <SmartTable
        columns={displayColumns}
        data={isMasterListValid ? masterRecipients : []}
        totalCount={isMasterListValid ? totalCount : 0}
        notFoundMessage={t("No master recipients found")}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onPageChange={onPageChange}
        onItemsPerPageChange={isMasterListValid && onItemsPerPageChange}
        testId="masterRecipients"
      />
      <FormDialog
        open={open}
        resource="Recipient"
        onClose={() => setOpen(false)}
        onSave={onConfirmEdit}
        fields={formFields}
        data={selectedRecipient.fields}
      />
      <DeleteDialog
        open={Boolean(recipientToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={recipientToDelete?.name}
        resource={t("Recipient")}
      />
      <SuccessDialog
        open={Boolean(successOpen)}
        onClose={() => setSuccessOpen(false)}
        name={selectedRecipient?.fields?.name}
        action={action}
        resource={"Recipient"}
      />
    </Page>
  );
}

export default memo(forwardRef(MasterRecipientListsBase));
