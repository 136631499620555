import * as api from "../api/dns";
import { notify } from "./notification";

export const LIST_DNS = "LIST_DNS";
export const CLEAR_DNS = "CLEAR_DNS";
export const GET_DNS = "GET_DNS";
export const SAVE_DNS = "SAVE_DNS";

export function clearDns() {
  return { type: CLEAR_DNS };
}

export function saveDns(dns) {
  return async (dispatch, getState) => {
    try {
      await api.saveDns(dns);
      dispatch(
        notify({
          type: "success",
          message: "DND set successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: GET_DNS,
        dnsData: {
          errors: {
            startAt: message.indexOf("start") === -1 ? false : true,
            endAt: message.indexOf("end") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
    dispatch(clearDns());
  };
}

export function deleteDns(paid) {
  return async (dispatch, getState) => {
    try {
      await api.deleteDns(paid);
      dispatch(clearDns());
      dispatch(
        notify({
          type: "success",
          message: "DND removed successfully!",
        })
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch(
        notify({
          type: "error",
          message: "Failed to remove DND",
        })
      );
    }
  };
}

export function updateDns(dns) {
  return async (dispatch, getState) => {
    try {
      await api.updateDns(dns);
      dispatch(
        notify({
          type: "success",
          message: "DND set successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: GET_DNS,
        dnsData: {
          errors: {
            startAt: message.indexOf("start") === -1 ? false : true,
            endAt: message.indexOf("end") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
    dispatch(clearDns());
  };
}

export function getDns(paid) {
  return async (dispatch, getState) => {
    const dnsData = await api.listDns({ paid: paid });
    dispatch({
      type: GET_DNS,
      dnsData: dnsData,
    });
  };
}
