import React, { useMemo, useCallback, memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  FormGroup,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField,
  DateTimePicker,
  Checkbox,
  MenuItem,
  Chip,
} from "../../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { difference } from "lodash";
import { Typography } from "@material-ui/core";

const tagNames = [
  "Unplanned Outage",
  "Planned Outage",
  "Emergency Notification",
  "Planned Notification",
  "Energy Efficiency",
  "Billing & Payments",
  "Other",
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  phoneInput: {
    width: "100% !important",
  },
}));

function CampaignMetaFields({
  name = "",
  permission = "",
  schedule,
  smsOptover = false,
  confirmServiceAddress = "",
  recipientHeaders = [],
  bypassSuppression = false,
  bypassDndDns = false,
  isHighPriority = false,
  tagss = "",
  errors = {},
  readOnly,
  onChange,
  onToggle,
  sendAsApproved = true,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const [tags, setTags] = useState(tagss ? tagss.trim().split(",") : []);
  const [tagValue, setTagValue] = useState("");
  const [hasOtherTag, setHasOtherTag] = useState(false);
  const [otherTagName, setOtherTagName] = useState("");
  const [otherFieldError, setOtherFieldError] = useState(false);

  const {
    vendorConfig: { dnd, dns, disableUserPermissions = [] },
  } = useSelector((state) => state.eonsConfig);
  const canBypassDndDns = dnd || dns;
  const shouldDisplayOnlyMe = !disableUserPermissions?.includes("campaign");

  useEffect(() => {
    //Finding the tag name in case of OTHER option
    //as it is input by user in that case
    const diff = difference(tags, tagNames); //(tags,tagName) Order is important
    if (tags.includes("Other")) {
      setOtherTagName(diff[0]); //diff[0] has the name for OTHER
      setHasOtherTag(true);
    }

    //When the field is not returned from BE,
    //Add this to trigger validation check
    if (!schedule) {
      onChange({ target: { name: "schedule", value: null } });
    }

    return () => { };
  }, [tags]);

  const onAddChip = useCallback(
    (event) => {
      const tag = event.target.value;
      const newTags = Object.assign([], tags);
      if (tag) {
        if (newTags && newTags.indexOf(tag) == -1) {
          newTags.push(tag.trim());
          setTags(newTags);
          setTagValue("");
          onChange({ target: { name: "tags", value: newTags?.toString() } });
        }
      }
    },
    [tags]
  );

  const deleteTag = (indx) => {
    const newTags = Object.assign([], tags);
    const tagToRemove = newTags[indx];
    newTags.splice(indx, 1);
    if (tagToRemove === "Other") {
      setOtherTagName("");
      setHasOtherTag(false);
      setOtherFieldError(false);
      const diff = difference(tags, tagNames);
      let temp = newTags.filter((item) => item !== diff[0]); //Also removing the user input value for other tag
      setTags(temp);
      onChange({ target: { name: "tags", value: temp?.toString() } });
    } else {
      setTags(newTags);
      onChange({ target: { name: "tags", value: newTags?.toString() } });
    }
  };

  function createChips(tags, classes) {
    const readOnly = false;
    return (
      <ul className={classes.root}>
        {tags.map((tag, idx) => {
          if (tagNames.includes(tag)) {
            //Listing only the dropdown values and not the user input value
            return (
              <li key={idx}>
                <Chip
                  label={tag}
                  onDelete={!readOnly && (() => deleteTag(idx))}
                  className={classes.chip}
                />
              </li>
            );
          }
        })}
      </ul>
    );
  }

  const allTags = useMemo(() => createChips(tags, classes), [tags, classes]);

  const checkOtherField = () => {
    if (!otherTagName || otherTagName === "") {
      setOtherFieldError(true);
    } else {
      setOtherFieldError(false);
      const newTags = Object.assign([], tags);
      if (newTags && newTags.indexOf(otherTagName) == -1) {
        newTags.push(otherTagName.trim());
        setTags(newTags);
        onChange({ target: { name: "tags", value: newTags?.toString() } });
      }
    }
  };

  return (
    <Paper {...rest} p={2}>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={6} container>
          <TextField
            name="name"
            value={name}
            onChange={onChange}
            label={t("Campaign Name")}
            variant="outlined"
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name}
            InputLabelProps={{ shrink: Boolean(name) }}
            InputProps={readOnlyProps}
            data-test-id="campaign-name"
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <FormControl component="fieldset" error={Boolean(errors.permission)}>
            <FormLabel component="legend">{t("Visibility Level")}</FormLabel>
            <RadioGroup
              row
              name="permission"
              onChange={!readOnly && onChange}
              value={permission}
              data-test-id="campaign-permission"
            >
              {shouldDisplayOnlyMe ? (
                <FormControlLabel
                  value="user"
                  control={<Radio color="primary" />}
                  label={t("Only Me")}
                  data-test-id="campaign-permission-user"
                />
              ) : (
                ""
              )}
              <FormControlLabel
                value="division"
                control={<Radio color="primary" />}
                label={t("My Division")}
                data-test-id="campaign-permission-division"
              />
              <FormControlLabel
                value="company"
                control={<Radio color="primary" />}
                label={t("My Company")}
                data-test-id="campaign-permission-company"
              />
            </RadioGroup>
            {errors.permission && (
              <FormHelperText data-test-id="campaign-permission-error">
                {errors.permission}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={5}>
              <FormControl error>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sendAsApproved}
                      onChange={onToggle}
                      name="sendAsApproved"
                    />
                  }
                  label={t("Send as soon as approved")}
                  data-test-id="campaign-send-now"
                />
                {errors.sendAsApproved && (
                  <FormHelperText>{errors.sendAsApproved}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2} style={{ textAlign: 'center' }}>
              <Typography variant="body1">OR</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <DateTimePicker
                label={t("Schedule")}
                fullWidth
                date
                time
                disablePast
                defaultValue={null}
                onAccept={(schedule) =>
                  onChange({ target: { name: "schedule", value: schedule } })
                }
                onChange={(schedule) => {
                  onChange({ target: { name: "schedule", value: schedule } });
                }}
                size="medium"
                error={Boolean(errors.schedule)}
                helperText={errors.schedule}
                data-test-id="campaign-schedule"
                name="campaign-schedule"
                disabled={Boolean(sendAsApproved)}
              />
            </Grid>
            <div>
            Ensure you select a schedule time appropriate for your approval
            timing. If a campaign remains waiting for approval for too long, the
            messages may be considered expired before the campaign is approved.
          </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <FormControl error>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={bypassSuppression}
                  onChange={onToggle}
                  name="bypassSuppression"
                />
              }
              label={t("Bypass Suppression")}
              data-test-id="campaign-bypass-suppression"
            />
            {errors.bypassSuppression && (
              <FormHelperText>{errors.bypassSuppression}</FormHelperText>
            )}
          </FormControl>
          {canBypassDndDns && (
            <FormControl error>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={bypassDndDns}
                    onChange={onToggle}
                    name="bypassDndDns"
                  />
                }
                label={t("Bypass DND")}
                data-test-id="campaign-bypass-dnd-dns"
              />
              {errors.bypassDndDns && (
                <FormHelperText>{errors.bypassDndDns}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl error>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isHighPriority}
                  onChange={onToggle}
                  name="isHighPriority"
                />
              }
              label={t("Is High Priority")}
              data-test-id="campaign-high-priority"
            />
            {errors.isHighPriority && (
              <FormHelperText>{errors.isHighPriority}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            // type="text"
            select
            name="tags"
            value={tagValue}
            label={t("Tags")}
            variant="outlined"
            fullWidth
            onChange={(event) => {
              setTagValue(event.target.value);
              onAddChip(event);
            }}
            // onKeyPress={(event) => {
            //   if (event.key === "Enter") {
            //     setTagValue(event.target.value);
            //     onAddChip(event);
            //   }
            // }}
            onBlur={onAddChip}
            data-test-id="campaign-tags"
          >
            {tagNames.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          {allTags}
          {hasOtherTag && (
            <TextField
              name="otherTagName"
              value={otherTagName}
              onChange={(event) => {
                setOtherTagName(event.target.value);
                setOtherFieldError(false);
              }}
              label={t("Specify other")}
              variant="outlined"
              fullWidth
              onBlur={checkOtherField}
              error={Boolean(otherFieldError)}
              helperText={
                otherFieldError &&
                `This is required as you have chosen 'Other' as one of the tags!`
              }
              // InputLabelProps={{ shrink: Boolean(name) }}
              // InputProps={readOnlyProps}
              size="small"
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(CampaignMetaFields);
